<template>
  <swiper :options="swiperOption" class="SlideOne">
    <swiper-slide class="swiper-slide">
      <div class="container">
        <div class="qrcodeBox">
          <div class="con">
            <h1 class="title">奇拉比先生图集官网</h1>
            <el-image
              class="qrcode"
              src="http://www.bryantout.online/qrcode_for_gh_89bf3ff4aef7_1280.jpg"
              fit="cover"
            ></el-image>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
export default {
  name: "SlideOne",
  data() {
    return {
      swiperOption: {
        // autoplay: 1000
        // loop: true
      },
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
.SlideOne {
  width: 100%;
  height: 100%;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    opacity: 1;
    background: url(http://www.bryantout.online/bg1.jpeg) no-repeat;
    background-size: 100% 100%;
    .qrcodeBox {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      width: 400px;
      height: 80%;
      background-color: rgba(255, 255, 266, 0.8);
      .con {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          color: #353535;
          margin-bottom: 30px;
        }
        .qrcode {
          width: 200px;
          display: block;
        }
      }
    }
  }
}
</style>